import {
    Alert,
    Button,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField
} from "@mui/material";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { API_CLIENTS, API_URL } from "../../utils/api/ApiPaths";
import { putClients } from "../../utils/api/Clients";
  
  export type MyFormState = {
    actif: string;
    name: string;
    vorname: string;
    email: string;
    phone: string;
    adress: string;
    city: string;
    postal: string;
    payment: string;
    session: string;
    actualSession: number;
  };
  

  
  const Editing = (props: any) => {
  
    const [clients, setClients] = useState<MyFormState[]>([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

 
  
    const [state, setState] = useState<MyFormState>({
      actif: "",
      name: "",
      vorname: "",
      email: "",
      phone: "",
      adress: "",
      city: "",
      postal: "",
      payment: "",
      session: "",
      actualSession: 0,
    });

    const getClientById = async (id: string) => {
        const response = await fetch(API_URL + API_CLIENTS + '/' + id, {
          credentials: 'include',
        });
        const data = await response.json();
        return data;
      };
      const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbarOpen(false);
      };

      
      
      useEffect(() => {
        const fetchData = async () => {
          if (props.selectedData.idClient) {
            const clientData = await getClientById(props.selectedData.idClient);
            setState({
              actif: clientData.actif,
              name: clientData.name,
              vorname: clientData.vorname,
              email: clientData.email,
              phone: clientData.phone,
              adress: clientData.adress,
              city: clientData.city,
              postal: clientData.postal,
              payment: clientData.payment,
              session: clientData.session,
              actualSession: clientData.actualSession,
            });
          }
        };
      
        fetchData();
      }, []);
      
  
    const formRef = useRef<HTMLFormElement>(null);
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
      });
    };
  
    const handleChangeSelect = (event: SelectChangeEvent) => {
      const { name, value } = event.target;
  
      setState({ ...state, [name]: value });
    };
  
    /** Submit */
    const handleSubmit = async (event: FormEvent) => {
      console.log(props.selectedData);
  
      console.log(event);
      event.preventDefault();
      const form = formRef.current;
      if (!form) return;
  
      const data = new FormData(form);
      const client = Object.fromEntries(data);
      // Envoyer le client ici en utilisant une API ou autre méthode
  
      await putClients(props.selectedId, client);
      setSnackbarOpen(true);

    };
  
    const resetForm = () => {
      setState({
        actif: "actif",
        name: "",
        vorname: "",
        email: "",
        phone: "",
        adress: "",
        city: "",
        postal: "",
        payment: "",
        session: "",
        actualSession: 0,
      });
    };
  
    return (
      <div>
      <p> {`Modification de : ${props.selectedName} `}</p>
          <div>
            <form ref={formRef} onSubmit={handleSubmit}>
              <FormControl style={{ width: "92%", marginLeft: "2%" }}>
                <InputLabel id="demo-simple-select-actif" htmlFor="actif">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-actif"
                  native
                  label="Status"
                  inputProps={{
                    name: "actif",
                    id: "actif",
                  }}
                  value={state.actif}
                  onChange={handleChangeSelect}
                >
                  <option value="actif">Actif</option>
                  <option value="inactif">Inactif</option>
                  <option value="prospect">Prospect</option>
                </Select>
              </FormControl>
              <br />
              <TextField
                label="Prénom"
                name="vorname"
                id="vorname"
                fullWidth
                value={state.vorname}
                onChange={handleChange}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              />
              <TextField
                label="Nom"
                name="name"
                id="name"
                fullWidth
                value={state.name}
                onChange={handleChange}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              />
  
              <br />
              <TextField
                label="Email"
                type="email"
                name="email"
                id="email"
                fullWidth
                value={state.email}
                onChange={handleChange}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              />
              <TextField
                label="Téléphone : +33"
                name="phone"
                id="phone"
                fullWidth
                value={state.phone}
                onChange={handleChange}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              />
              <br />
              <TextField
                label="Code Postal"
                name="postal"
                id="postal"
                fullWidth
                value={state.postal}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
                onChange={handleChange}
              />
              <TextField
                label="Ville"
                name="city"
                id="city"
                fullWidth
                value={state.city}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
                onChange={handleChange}
              />
  
              <TextField
                label="Complément d'adresse"
                name="adress"
                id="adress"
                fullWidth
                value={state.adress}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
                onChange={handleChange}
              />
              <FormControl
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              >
                <InputLabel id="demo-simple-select-label" htmlFor="payment">
                  Méthode de paiement
                </InputLabel>
                <Select
                  value={state.payment}
                  labelId="demo-simple-select-label"
                  native
                  label="Méthode de paiement"
                  inputProps={{
                    name: "payment",
                    id: "payment",
                  }}
                >
                  <option value="mounth">Paiement par mois</option>
                  <option value="one">Paiement en une fois</option>
                </Select>
              </FormControl>
              <br />
              <TextField
                label="Nombre de séance"
                name="session"
                id="session"
                fullWidth
                value={state.session}
                onChange={handleChange}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              />
  
              <TextField
                label="Séance réalisée :"
                name="actualSession"
                id="actualSession"
                fullWidth
                value={state.actualSession}
                onChange={handleChange}
                sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              />
              <br/>
              <Button variant="contained" sx={{ marginLeft: "2%", marginTop: "1%" }} onClick={handleSubmit}>
                Enregistrer
              </Button>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}

             >
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Modification réussie!
      </Alert>
    </Snackbar>
          </div>
        </div>
    );
  };
  
  export default Editing;
  