import { createFilterOptions } from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  API_APPOINTMENTS,
  API_CLIENTS,
  API_URL,
} from "../../utils/api/ApiPaths";

export interface ClientsType {
  id: number;
  name: string;
  vorname: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
}

interface JsonObject {
  title: string;
  start: string;
  end: string;
  extendedProps: {
    _id: string;
    idClient: string;
  };
}

const filter = createFilterOptions<any>();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModifDialog = (props: any) => {
  const handleClose = () => {
    props.setModalEdit(false);
  };
  const objEvent: JsonObject = props.tmpData;
  const nameClient = objEvent.title;

  const [clientNameList, setClientNameList] = useState<string[]>([]); // options for the autocomplete

  const [clienttmp, setClientTmp] = useState<string[]>([]);
  const [clientList, setClientList] = useState<Map<string, number>>(new Map()); // association name with id, so we can find easily the card after
  const [valueSend, setValueSend] = useState<any[]>([]);

  /** Send date to planning */
  const handleSend = async () => {
    const idAppointments = objEvent.extendedProps._id;
    const idClient = objEvent.extendedProps.idClient;

    console.log("client id: " + idClient);
    console.log(" id app: " + idAppointments);

    const response = await fetch(
      API_URL +
        API_CLIENTS +
        "/" +
        idClient +
        API_APPOINTMENTS +
        "/" +
        idAppointments,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    // Close the dialog
    props.setModalEdit(false);
  };

  useEffect(() => {
    console.log(JSON.stringify(props.tmpData));
  }, [props.modalEdit]);

  return (
    <div>
      <Dialog
        open={props.modalEdit}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Supprimer le rendez-vous"}</DialogTitle>
        <DialogContent>
          <p>
            Voulez-vous supprimer le rendez-vous avec :{" "}
            <span style={{ color: "red" }}>{nameClient}</span>
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginRight: "2%" }}
          >
            Annuler
          </Button>
          <Button variant="contained" onClick={handleSend}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModifDialog;
