import {
  Mail,
  MailOutlineOutlined,
  SmsFailedOutlined,
  SmsSharp,
} from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { CustomTooltipArrow } from "../../_helpers/CustomTooltip";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CheckFilter = () => {
  return (
    <div>
      <CustomTooltipArrow placement="bottom" title={"Envoie par sms  "}>
        <Checkbox
          sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
          {...label}
          icon={<SmsFailedOutlined />}
          checkedIcon={<SmsSharp />}
        />
      </CustomTooltipArrow>
      <CustomTooltipArrow placement="bottom" title={"Envoie par mail  "}>
        <Checkbox
          sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
          {...label}
          icon={<MailOutlineOutlined />}
          checkedIcon={<Mail />}
        />
      </CustomTooltipArrow>
    </div>
  );
};
export default CheckFilter;
