// Types
import { LoginType, UserType } from "../Types";

// paths & errors
import {
  API_ASK_RESET_PASSWORD,
  API_CONFIRM_EMAIL,
  API_LOGIN,
  API_LOGOUT,
  API_RESET_PASSWORD,
  API_UPDATE_EMAIL,
  API_UPDATE_PASSWORD,
  API_UPDATE_USERNAME,
  API_URL,
} from "./ApiPaths";
import {
  ERROR_CODE_INVALID,
  ERROR_EMAIL_ALREADY_TAKEN,
  ERROR_INVALID_CREDENTIALS,
  ERROR_INVALID_USERNAME,
  ERROR_RESET_EXPIRED,
  ERROR_RESET_WRONG_UUID,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
  ERROR_USER_DO_NO_EXIST,
  ERROR_WRONG_RESPONSE,
} from "./Errors";

// axios
import axios from "axios";

/*****************************/
/** AUTHENTICATION HELPERS **/
/****************************/

/**
 * Login through the API
 * @param email
 * @param password
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_INVALID_CREDENTIALS
 * @error ERROR_UNKNOWN
 */
export const login = (email: string, password: string): Promise<LoginType> => {
  return axios
    .post(
      API_URL + API_LOGIN,
      {
        email,
        password,
      },
      { withCredentials: true }
    )
    .then((response) => {
      if (response.data) {
        // process the response to return token & UserType
        try {
          const token: string = response.data.token;

          const id = parseInt(response.data.user.id);
          const name = response.data.user.name;
          const email = response.data.user.email;
          const friendCode = response.data.user.friend_code;
          const user: UserType = { id, name, email, friendCode };

          return { token, user } as LoginType;
        } catch (error) {
          console.error("Converting data to User: FAILED >> " + error);
          throw ERROR_WRONG_RESPONSE;
        }
      } else {
        console.error("Missing Data in the response");
        throw ERROR_WRONG_RESPONSE;
      }
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        // invalid credentials
        console.error(error);
        throw ERROR_INVALID_CREDENTIALS;
      } else {
        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};
function clearToken() {
  localStorage.removeItem("tbsid");
}

/**
 * Logout through the API
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_UNAUTHORIZED
 * @error ERROR_UNKNOWN
 */
export const logout = () => {
  return axios
    .get(API_URL + API_LOGOUT, { withCredentials: true })
    .then(() => {
      clearToken();
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        clearToken();

        // not authorized
        console.error(error);
        throw ERROR_UNAUTHORIZED;
      } else {
        clearToken();

        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};

/**
 * Confirm the email of a user with a code (sent by email)
 * @param userId
 * @param code
 * @error ERROR_CODE_INVALID
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const confirmEmail = (userId: string, code: string) => {
  return axios
    .post(
      API_URL + API_CONFIRM_EMAIL.replace(":id", userId),
      {
        code: code,
      },
      { withCredentials: true }
    )
    .catch((error) => {
      if (error.response.status === 403) throw ERROR_CODE_INVALID;
      else {
        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};

/**
 *
 * @param email
 * @error ERR_EMAIL_ALREADY_TAKEN
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updateEmail = (email: string) => {
  return axios
    .post(
      API_URL + API_UPDATE_EMAIL,
      {
        email: email,
      },
      { withCredentials: true }
    )
    .catch((error) => {
      if (error.response.error_key === "ERR_EMAIL_ALREADY_EXISTS") {
        throw ERROR_EMAIL_ALREADY_TAKEN;
      } else {
        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};

/**
 * Change user's password
 * @param currentPassword
 * @param newPassword
 * @error ERROR_INVALID_CREDENTIALS
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updatePassword = (
  currentPassword: string,
  newPassword: string
) => {
  return axios
    .post(
      API_URL + API_UPDATE_PASSWORD,
      {
        current_password: currentPassword,
        new_password: newPassword,
      },
      { withCredentials: true }
    )
    .catch((error) => {
      if (error.response.status === 403) {
        throw ERROR_INVALID_CREDENTIALS;
      } else {
        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};

/**
 * Change user's name
 * @param username
 * @error ERROR_INVALID_USERNAME
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updateUsername = (username: string) => {
  return axios
    .post(
      API_URL + API_UPDATE_USERNAME,
      {
        name: username,
      },
      { withCredentials: true }
    )
    .catch((error) => {
      if (error.response.status === 400) {
        throw ERROR_INVALID_USERNAME;
      } else {
        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};

/**
 * It initiates the reset password process. The user will receive a mail with the reset_UUID.
 * @param email account email
 * @error ERROR_USER_DO_NO_EXIST
 * @error ERROR_UNKNOWN
 */
export const askResetPassword = (email: string) => {
  return axios
    .post(API_URL + API_ASK_RESET_PASSWORD, { email: email })
    .catch((error) => {
      if (error.response.status === 401) throw ERROR_USER_DO_NO_EXIST;
      else {
        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};

/**
 * It allows users to update their password with the correct uuid they received by mail.
 * @param reset_uuid
 * @param password new password
 * @error ERROR_RESET_EXPIRED
 * @error ERROR_RESET_WRONG_UUID
 * @error ERROR_UNKNOWN
 */
export const resetPassword = (reset_uuid: string, password: string) => {
  return axios
    .post(API_URL + API_RESET_PASSWORD, {
      reset_uuid: reset_uuid,
      new_password: password,
    })
    .catch((error) => {
      if (error.response.status === 403) {
        // request was processed by the server
        if (error.response.data.message) {
          const errMsg: string = error.response.data.message;
          if (errMsg === "Cannot update password reset has expired")
            throw ERROR_RESET_EXPIRED;
          else if (errMsg === "Cannot update password uuid doesn't match")
            throw ERROR_RESET_WRONG_UUID;
          else {
            // we don't know :'(
            console.error(error);
            throw ERROR_UNKNOWN;
          }
        } else {
          // we don't know :'(
          console.error(error);
          throw ERROR_UNKNOWN;
        }
      } else {
        // we don't know :'(
        console.error(error);
        throw ERROR_UNKNOWN;
      }
    });
};
