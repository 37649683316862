import { useEffect } from "react";
// AUTH
// NAV
import { useLocation, useNavigate } from "react-router-dom";
// SEO
// Error handling
// Components
import AuthService from "../../utils/authentication/Service";
import SnackPage from "../../_helpers/SnackPage";
import SignInForm from "./SignInForm";

const SignInPage = () => {
  //  nav
  let navigate = useNavigate();
  let location: any = useLocation();
  let directTo = location.state?.from?.pathname || "/";

  // auth
  const isAuth = AuthService.isAuthenticated();

  useEffect(() => {
    // if the user is connected, immediate redirection
    // navigate must be in the useEffect if outside the render
    if (isAuth) {
      navigate(directTo, { replace: true });
    }
  });

  return (
    <div>
      <SignInForm directTo={directTo} />
      <SnackPage />
    </div>
  );
};

export default SignInPage;
