/*************/
/** IMPORTS **/
/*************/

import { login, logout } from "./Users";

import {
  ERROR_CANNOT_BE_YOUR_OWN_FRIEND,
  ERROR_CANNOT_DELETE_PERSONAL_LIBRARY,
  ERROR_CANNOT_EDIT_OWNER_ACCESS_RIGHT,
  ERROR_CARD_ALREADY_IN_ALBUM,
  ERROR_CARD_NAME_ALREADY_TAKEN,
  ERROR_CARD_NOT_IN_ALBUM,
  ERROR_CODE_INVALID,
  ERROR_CONTACT_REQUEST_ALREADY_EXISTS,
  ERROR_EMAIL_ALREADY_TAKEN,
  ERROR_EXISTING_CONTACT,
  ERROR_INSUFFISANT_RIGHTS,
  ERROR_INVALID_CREDENTIALS,
  ERROR_INVALID_USERNAME,
  ERROR_NOT_FOUND,
  ERROR_NO_ACCESS,
  ERROR_RESET_EXPIRED,
  ERROR_RESET_WRONG_UUID,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
  ERROR_UNKNOWN_CONTACT_REQUEST,
  ERROR_UNKNOWN_FRIEND_CODE,
  ERROR_USER_DO_NO_EXIST,
  ERROR_WRONG_RESPONSE,
} from "./Errors";

/*************/
/** EXPORTS **/
/*************/

const ApiService = {
  // USERS
  login,
  logout,

  // ERRORS
  ERROR_INVALID_CREDENTIALS,
  ERROR_USER_DO_NO_EXIST,
  ERROR_EMAIL_ALREADY_TAKEN,
  ERROR_INVALID_USERNAME,
  ERROR_CODE_INVALID,
  ERROR_RESET_EXPIRED,
  ERROR_RESET_WRONG_UUID,
  ERROR_UNAUTHORIZED,
  ERROR_NOT_FOUND,
  ERROR_NO_ACCESS,
  ERROR_WRONG_RESPONSE,
  ERROR_INSUFFISANT_RIGHTS,
  ERROR_CARD_NAME_ALREADY_TAKEN,
  ERROR_CANNOT_DELETE_PERSONAL_LIBRARY,
  ERROR_CARD_ALREADY_IN_ALBUM,
  ERROR_CARD_NOT_IN_ALBUM,
  ERROR_CANNOT_BE_YOUR_OWN_FRIEND,
  ERROR_CONTACT_REQUEST_ALREADY_EXISTS,
  ERROR_EXISTING_CONTACT,
  ERROR_UNKNOWN_FRIEND_CODE,
  ERROR_UNKNOWN_CONTACT_REQUEST,
  ERROR_CANNOT_EDIT_OWNER_ACCESS_RIGHT,
  ERROR_UNKNOWN,
};

export default ApiService;
