import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import DemoApp from "./components/calendar/DemoApp";
import Clients from "./components/clients/Clients";
import LeftNav from "./components/navigation/LeftNav";
import Relations from "./components/relation/Relation";
import SignInPage from "./components/signin/SignIn";
import theme from "./Theme";
import ProtectedRoutes from "./utils/authentication/ProtectedRoutes";
import PublicLayout from "./utils/PublicLayout";

/**
 * APP
 * Main entry of the application, called from index.tsx
 * Used mainly for :
 * - contexts initialisation
 * - routing management
 * @returns main component with all basic initialisations (context & routing)
 */
const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <LeftNav />

          <Routes>
            <Route element={<PublicLayout />}>
              <Route path="/Connexion" element={<SignInPage />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<DemoApp />} />

              <Route path="/Clients" element={<Clients />} />

              <Route path="/Communication" element={<Relations />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
