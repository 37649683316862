import axios from "axios";
import { API_CLIENTS, API_URL } from "./ApiPaths";

export const postClients = (data: any) => {
  return axios
    .post(API_URL + API_CLIENTS, data, { withCredentials: true })
    .catch((error) => {
      if (error) {
        console.log(error);
      } else {
        // we don't know :'(
        console.error(error);
      }
    });
};

export const putClients = (id: any, data: any) => {
  return axios
    .put(API_URL + API_CLIENTS + "/" + id, data, { withCredentials: true })
    .catch((error) => {
      if (error) {
        console.log(error);
      } else {
        // we don't know :'(
        console.error(error);
      }
    });
};

export const getClients = () => {
  return axios
    .get(API_URL + API_CLIENTS, { withCredentials: true })
    .catch((error) => {
      if (error) {
        console.log("Une erreur s'est produite");
      } else {
        console.log("?????????");
        // we don't know :'(
        console.error(error);
      }
    });
};
