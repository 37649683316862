// Navigation
import { Outlet } from "react-router-dom";
// Error handling
// Authentication
import AuthService from "./authentication/Service";
// Components

// MUI IMPORTS
import Box from "@mui/material/Box";

// theming

// COMPONENT
const PublicLayout = () => {
  // auth
  const isAuth = AuthService.isAuthenticated();

  // RETURN
  return (
    <Box sx={{ display: "flex" }} className="PublicLayout">
      {/* APP BAR */}
      <Box component="main" sx={{ flexGrow: 1, pt: 8, px: 1 }}>
        {/* PAGES */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default PublicLayout;
