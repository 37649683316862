import { Logout, Settings } from "@mui/icons-material";
import { Button, ListItemIcon, ListItemText } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/api/Users";
import { CustomTooltipArrow } from "../../_helpers/CustomTooltip";

const MenuSettings = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  const logoutClick = () => {
    logout()
      .then(() => {
        navigate("/Connexion");
      })
      .catch((error) => {
        navigate("/Connexion");
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="paramPos">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Settings />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <CustomTooltipArrow placement="bottom" title={"Me déconnecter  "}>
          <MenuItem onClick={logoutClick}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Me déconnecter</ListItemText>
          </MenuItem>
        </CustomTooltipArrow>
      </Menu>
    </div>
  );
};

export default MenuSettings;
