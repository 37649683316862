import { Add } from "@mui/icons-material";
import { Box, Button, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import TableClients from "./TableClients";

const Clients = () => {
  const [modal, setModal] = useState(false);

  const [queryStatus, setQueryStatus] = useState("all");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryStatus(event.target.value);
  };

  const createClients = () => {
    setModal(true);
  };
  return (
    <Box
      sx={{
        marginLeft: { xs: "15%", sm: "9%", md: "6%", lg: "7%" },
        marginRight: { xs: "8%", sm: "7%", md: "6%", lg: "7%" },
      }}
    >
      {" "}
      <div
        style={{
          position: "absolute",
          top: "0%",
          left: "43%",
          backgroundColor: "#02BDA1",
          paddingLeft: "3%",
          paddingRight: "3%",
        }}
      >
        <h3 style={{ color: "white" }}>Mes Clients</h3>
      </div>
      <Box sx={{ marginTop: { xs: "25%", sm: "17%", md: "6%", lg: "7%" } }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button onClick={createClients} variant="contained">
            <Add />
          </Button>
          <RadioGroup
            aria-label="status"
            name="status"
            row={true}
            value={queryStatus}
            onChange={handleChange}
          >
            <Radio value="all" sx={{ display: "inline-block" }} />
            Tous
            <Radio value="actif" sx={{ display: "inline-block" }} />
            Actif
            <Radio value="inactif" sx={{ display: "inline-block" }} />
            Inactif
            <Radio value="prospect" sx={{ display: "inline-block" }} />
            Prospect
          </RadioGroup>
        </div>
      </Box>
      <div style={{ marginTop: "0.5%" }}>
        <TableClients
          modal={modal}
          setModal={setModal}
          queryStatus={queryStatus}
        />
      </div>
    </Box>
  );
};
export default Clients;
