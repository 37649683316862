// Navigation
import { Navigate, Outlet, useLocation } from "react-router-dom";

// Authentication helpers
import AuthService from "./Service";

/**
 * Check if the user is authenticated
 * @returns the content is the user is authenticated, or send the user to the connexion page
 */
const ProtectedRoutes = () => {
  let location = useLocation();
  const isAuth = AuthService.isAuthenticated();
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/Connexion" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
