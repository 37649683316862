// api paths
export const API_URL: string = process.env.REACT_APP_API_base_url as string;
export const API_LOGIN: string = process.env.REACT_APP_API_login as string;
export const API_LOGOUT: string = process.env.REACT_APP_API_logout as string;
export const API_CLIENTS: string = process.env.REACT_APP_API_clients as string;
export const API_PLANNING: string = process.env
  .REACT_APP_API_planning as string;
export const API_APPOINTMENTS: string = process.env
  .REACT_APP_API_appointments as string;

export const API_CONFIRM_EMAIL: string = process.env
  .REACT_APP_API_confirm_email as string;
export const API_UPDATE_EMAIL: string = process.env
  .REACT_APP_API_update_email as string;
export const API_UPDATE_USERNAME: string = process.env
  .REACT_APP_API_update_infos as string;
export const API_ASK_RESET_PASSWORD: string = process.env
  .REACT_APP_API_ask_reset_password as string;
export const API_RESET_PASSWORD: string = process.env
  .REACT_APP_API_reset_password as string;
export const API_UPDATE_PASSWORD: string = process.env
  .REACT_APP_API_update_password_me as string;
export const API_LIBRARY: string = process.env.REACT_APP_API_library as string;
export const API_ALBUM: string = process.env.REACT_APP_API_albums as string;
export const API_CARD: string = process.env.REACT_APP_API_cards as string;
export const API_LINK: string = process.env.REACT_APP_API_links as string;
export const API_TAG: string = process.env.REACT_APP_API_tags as string;
export const API_TODO: string = process.env.REACT_APP_API_todos as string;
export const API_ASSOCIATE: string = process.env
  .REACT_APP_API_associate as string;
export const API_DISSOCIATE: string = process.env
  .REACT_APP_API_dissociate as string;
export const API_CONTACT: string = process.env.REACT_APP_API_contacts as string;
export const API_VALIDATE: string = process.env
  .REACT_APP_API_validate as string;
export const API_ACCESS_RIGHT: string = process.env
  .REACT_APP_API_access_rights as string;
export const API_SHARE: string = process.env.REACT_APP_API_share as string;
export const API_UNSHARE: string = process.env.REACT_APP_API_unshare as string;
