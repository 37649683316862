// DEFINE ERROR MESSAGES
export const ERROR_INVALID_CREDENTIALS = "Invalid Credentials";
export const ERROR_USER_DO_NO_EXIST = "User do not exist";
export const ERROR_EMAIL_ALREADY_TAKEN = "Email already taken";
export const ERROR_INVALID_USERNAME = "Username does not fit the requirements";
export const ERROR_CODE_INVALID = "Invalid confirmation code";
export const ERROR_RESET_EXPIRED = "Reset has expired, can not update password";
export const ERROR_RESET_WRONG_UUID = "Reset UUID does not match, can not update password";
export const ERROR_UNAUTHORIZED = "Unauthorized operation";
export const ERROR_NOT_FOUND = "Data not found";
export const ERROR_NO_ACCESS = "No right to access data";
export const ERROR_WRONG_RESPONSE = "Response Data not properly formatted";
export const ERROR_INSUFFISANT_RIGHTS = "User does not have enough rights to perform the action";

export const ERROR_CARD_NAME_ALREADY_TAKEN = "Another card exists with the same name in the library";
export const ERROR_CANNOT_DELETE_PERSONAL_LIBRARY = "User can not delete his own personal library";
export const ERROR_CARD_ALREADY_IN_ALBUM = "This card is already associated to this album";
export const ERROR_CARD_NOT_IN_ALBUM = "This card is not associated to this album";

export const ERROR_CANNOT_BE_YOUR_OWN_FRIEND = "This friend code belongs to the user sending the request ; you can't be your own friend";
export const ERROR_CONTACT_REQUEST_ALREADY_EXISTS = "Contact request already exists with this friend code";
export const ERROR_EXISTING_CONTACT = "Contact already exists with this friend code";
export const ERROR_UNKNOWN_FRIEND_CODE = "Unkown friend code";
export const ERROR_UNKNOWN_CONTACT_REQUEST = "Unkown contact request";

export const ERROR_CANNOT_EDIT_OWNER_ACCESS_RIGHT = "Cannot change owner's access rights";

export const ERROR_UNKNOWN = "Unknown error";
