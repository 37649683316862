import FullCalendar from "@fullcalendar/react"; // must go before plugins

import { EventApi } from "@fullcalendar/core";
import DayGridView from "@fullcalendar/daygrid";
import Interaction from "@fullcalendar/interaction";
import TimeGridView from "@fullcalendar/timegrid";
import { PaidOutlined, ViewAgenda } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  API_APPOINTMENTS,
  API_CLIENTS,
  API_URL
} from "../../utils/api/ApiPaths";
import { ModalNotesAgenda } from "../clients/ModalNotesAgenda";
import MenuSettings from "../customComponents/ParamPos";
import CreateDialog from "./CreateDialog";
import ModifDialog from "./ModifDialog";
import "./calendarStyle.css";
import events from "./event";

declare module "@fullcalendar/core" {
  interface PluginDef {
    dayGridView?: typeof DayGridView;
    interaction?: typeof Interaction;
    timeGridView?: typeof TimeGridView;
  }
}
declare module "@fullcalendar/react" {
  interface PluginDef {
    fullCalendar?: typeof FullCalendar;
  }
}
interface Appointment {
  start: string;
  end: string;
  _id: string;
}

interface Event {
  clientid: string;
  name: string;
  appointments: Appointment[];
}

export default function DemoApp() {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedData2, setSelectedData2] = useState<any | null>(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [clientAppointment, setClientAppointments] = useState([]);
  const [updateCountAgenda, setUpdateCountAgenda] = useState(0);

  const [openCommentaire, setOpenCommentaire] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const calendarRef: any = useRef(null);

  const [dataCallendar, setDataCallendar] = useState<any>();

  const [title, setTitle] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [state, setState]: any = useState({});

  const getAppointmentsByClientId = (clientId: any) => {
    return fetch(API_URL + API_CLIENTS, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        const client = data.find((client: any) => client.id === clientId);

        if (!client) {
          throw new Error("Client not found");
        }

        console.log(client);
        return client.appointments.map((appointment: any) => {
          return {
            id: appointment._id,
            startDate: appointment.startDate,
            endDate: appointment.endDate,
            notes: appointment.notes,
            paid: appointment.paid, // Assurez-vous que 'notes' est bien la propriété correcte pour les notes
          };
        });
      });
  };

  function useHasPaidAppointment(clientId: string): boolean | null {
    const [hasPaidAppointment, setHasPaidAppointment] = useState<
      boolean | null
    >(null);

    useEffect(() => {
      async function fetchHasPaidAppointment() {
        try {
          const response = await fetch(
            API_URL + API_CLIENTS + `/${clientId}/has-paid-appointment`
          );
          if (!response.ok) {
            throw new Error(
              `Error fetching hasPaidAppointment: ${response.statusText}`
            );
          }

          console.log(response);
          const result = await response.json();
          console.log(result);

          setHasPaidAppointment(result);
        } catch (error) {
          console.error("Error fetching hasPaidAppointment:", error);
        }
      }

      fetchHasPaidAppointment();
    }, [clientId]);

    return hasPaidAppointment;
  }

  const handleOpenCommentaire = async (id: any, name: any, data: any) => {
    try {
      console.log(data);
      const appointments = await getAppointmentsByClientId(id);
      setTitle(name);
      setSelectedData2(data);
      setClientAppointments(appointments);
      setOpenCommentaire(true);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // Fetch data from API
    console.log('fetchhh')

    fetch(API_URL + API_CLIENTS, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        const transformedData = data.map((client: any) => {
          // Extrayez les données de chaque objet "appointments" ici
          return client.appointments.map(
            (appointment: {
              startDate: string;
              endDate: string;
              paid: boolean;
              _id: string;
            }) => {
              return {
                idClient: client.id,
                title: client.vorname + " " + client.name,
                name: client.vorname,
                vorname: client.vorname,
                start: appointment.startDate,
                _id: appointment._id,
                end: appointment.endDate,
                paid: appointment.paid,
                color: appointment.paid
                  ? "red"
                  : client.actif === "prospect"
                  ? "#6e9964"
                  : "",
              };
            }
          );
        });
        setDataCallendar(transformedData.flat());
      });

    /*
    const fetchData = async () => {
      const response = await fetch(API_URL + API_CLIENTS, {
        credentials: "include",
      });
      const data = await response.json();

      let datato: any = [
        {
          id: 1,
          title: "Timed event",
          start: "2022-12-12T13:00:00.000Z",
          end: "2022-12-12T13:30:00.000Z",
          // date: "2020-07-30"
        },
      ];
      datato = data.map((client: any) => ({
        id: 1,
        title: "Timed event",
        start: '2022-12-12T13:00:00.000Z"',
        end: "2022-12-12T13:30:00.000Z",
      }));

      console.log(datato);
      setDataCallendar(datato);
            */

    /*
      setDataCallendar([
        {
          id: 1,
          title: "Timed event",
          start: "2022-12-12T13:00:00.000Z",
          end: "2022-12-12T13:30:00.000Z",
          // date: "2020-07-30"
        },
      ]);
      */
    //};
    // fetchData();
  }, [modal, modalEdit, openCommentaire, updateCountAgenda]);

  const handleCloseModal = () => {
    handleClose();
    setModal(false);
  };
  function handleClose() {
    setTitle("");
    setStart(new Date());
    setEnd(new Date());
    setState({});
    setModal(false);
  }

  function handleDateSelect(selectInfo: any) {
    // console.log(selectInfo.view.type);
    if (
      selectInfo.view.type === "timeGridWeek" ||
      selectInfo.view.type === "timeGridDay"
    ) {
      selectInfo.view.calendar.unselect();
      //setState({ selectInfo, state: "create" });
      // Open modal create
      setStart(selectInfo.start);
      setEnd(selectInfo.end);
      setModal(true);
    }
  }
  function renderEventContent(eventInfo: any) {
    console.log(eventInfo.event._def);
    return (
      <EventContent
        eventInfo={eventInfo}
        handleOpenCommentaire={handleOpenCommentaire}
      />
    );
  }
  function EventContent({ eventInfo, handleOpenCommentaire }: any) {
    const hasPaidAppointment = useHasPaidAppointment(
      eventInfo.event.extendedProps.idClient
    );

    console.log(hasPaidAppointment);
    return (


<Grid container  spacing={0}>
  <Grid item xs={12} md={3}>
    <Button
      onClick={(event) => {
        console.log(eventInfo.event._def);
        event.stopPropagation();
        handleOpenCommentaire(
          eventInfo.event.extendedProps.idClient,
          eventInfo.event.title,
          eventInfo.event.extendedProps
        );
      }}
      variant="contained"
      sx={{
        maxWidth: "25px",
        maxHeight: "25px",
        minWidth: "25px",
        minHeight: "25px",
        width: "100%",
      }}
    >
      <ViewAgenda sx={{ width: "20px" }} />
    </Button>
  </Grid>
  <Grid item xs={12} md={12} >
    <Typography
      variant="subtitle2"
      noWrap
      sx={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
      }}
    >
      {eventInfo.event.title}
    </Typography>
  </Grid>
  <Grid item xs={12} md={12} >
    <Typography
    >
      {hasPaidAppointment && <PaidOutlined />}

    </Typography>
  </Grid>
</Grid>
    );
  }

  function handleEventClick(clickInfo: any) {
    // console.log("open modal update, delete");
    setState({ clickInfo, state: "update" });
    // set detail
    setTitle(clickInfo.event.title);
    setStart(clickInfo.event.start);
    setEnd(clickInfo.event.end);

    setModalEdit(true);
    // if (
    //   confirm(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove();
    // }
  }
  function handleEvents(events: any) {
    setCurrentEvents(events);
  }
  async function handleEventDrop(checkInfo: any) {
    console.log("??? drop");
    // checkInfo.revert();
    const newEndDate = checkInfo.event.end.toISOString();
    const newStartDate = checkInfo.event.start.toISOString();
    /** Modif drop ajouter la nouvelle startDate */
    console.log(checkInfo.event.start.toISOString());

    setState({ checkInfo, state: "drop" });
    const idClient = checkInfo.event.extendedProps.idClient;
    const idAppointments = checkInfo.event.extendedProps._id;

    console.log(newEndDate);
    console.log(newStartDate);

    const sendDate = {
      start: newStartDate,
      end: newEndDate,
    };
    const response = await fetch(
      API_URL +
        API_CLIENTS +
        "/" +
        idClient +
        API_APPOINTMENTS +
        "/" +
        idAppointments,
      {
        method: "PUT",
        body: JSON.stringify({
          start: newStartDate,
          end: newEndDate,
        }),
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  async function handleEventResize(checkInfo: any) {
    /** Modif Resize ajouter les nouvelles dates */
    const newEndDate = checkInfo.event.end.toISOString();
    const newStartDate = checkInfo.event.start.toISOString();

    console.log(typeof newStartDate);

    const idClient = checkInfo.event.extendedProps.idClient;
    const idAppointments = checkInfo.event.extendedProps._id;

    console.log(newEndDate);
    console.log(newStartDate);

    const sendDate = {
      start: newStartDate,
      end: newEndDate,
    };
    const response = await fetch(
      API_URL +
        API_CLIENTS +
        "/" +
        idClient +
        API_APPOINTMENTS +
        "/" +
        idAppointments,
      {
        method: "PUT",
        body: JSON.stringify({
          start: newStartDate,
          end: newEndDate,
        }),
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log(checkInfo);
    setState({ checkInfo, state: "resize" });

    setConfirmModal(true);
  }
  function handleEdit() {
    // console.log(start, end);
    // state.clickInfo.event.setAllDay(true);

    state.clickInfo.event.setStart(start);
    state.clickInfo.event.setEnd(end);
    state.clickInfo.event.mutate({
      standardProps: { title },
    });
    handleClose();
  }
  function handleSubmit() {
    // console.log(state.selectInfo.view.calendar);
    const newEvent = {
      id: 3,
      title,
      start: state.selectInfo?.startStr || start.toISOString(),
      end: state.selectInfo?.endStr || end.toISOString(),
      allDay: state.selectInfo?.allDay || false,
    };
    // console.log(newEvent);

    let calendarApi = calendarRef.current.getApi();
    // let calendarApi = selectInfo.view.calendar

    calendarApi.addEvent(newEvent);
    handleClose();
  }

  let todayStr = new Date().toISOString().replace(/T.*$/, "");

  return (
    <Box
      sx={{
        marginLeft: { xs: "17%", sm: "9%", md: "6%", lg: "7%" },
        marginRight: { xs: "2%", sm: "7%", md: "6%", lg: "7%" },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0%",
          left: "43%",
          backgroundColor: "#6E9964",
          paddingLeft: "4%",
          paddingRight: "4%",
        }}
      >
        <h3 style={{ color: "white" }}>Mon Planning</h3>
      </div>
      <div style={{ marginTop: "6%" }} className="calendar-container">
        <FullCalendar
          eventMinHeight={60}
          ref={calendarRef}
          events={dataCallendar}
          plugins={[Interaction, DayGridView, TimeGridView]}
          height="auto"
          firstDay={1}
          slotMinTime="05:00"
          slotMaxTime="22:00"
          slotDuration="00:30"
          headerToolbar={{
            left: "prev,today,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today: "Aujourd'hui",
            month: "Mois",
            week: "Semaine",
            day: "Jour",
            list: "Liste",
          }}
          locale="fr"
          initialView="timeGridWeek"
          timeZone="local"
          editable={true}
          selectable={true}
          allDaySlot={false}
          selectMirror={true}
          dayMaxEvents={true}
          nowIndicator={true}
          weekends={weekendsVisible}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          eventsSet={() => handleEvents(events)}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          eventAdd={(e) => {
            console.log("eventAdd", e);
          }}
          eventChange={(e) => {
            console.log("eventChange", e);
          }}
          eventRemove={(e) => {
            console.log("eventRemove", e);
          }}
        />
      </div>
      <CreateDialog
        modal={modal}
        setModal={setModal}
        startDate={start}
        endDate={end}
        dataCalendar={dataCallendar}
        setDataCalendar={setDataCallendar}
        onSubmit={state.clickInfo ? handleEdit : handleSubmit}
        submitText={state.clickInfo ? "Update" : "Save"}
        deleteText="Delete"
      />

      <ModifDialog
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        toggle={handleCloseModal}
        dataCallendar={dataCallendar}
        startDate={start}
        endDate={end}
        setDataCalendar={setDataCallendar}
        onCancel={handleCloseModal}
        onSubmit={state.clickInfo ? handleEdit : handleSubmit}
        submitText={state.clickInfo ? "Update" : "Save"}
        tmpData={state.clickInfo ? state.clickInfo.event : "r"}
        deleteText="Delete"
      />
      <MenuSettings />
      <ModalNotesAgenda
        isOpen={openCommentaire}
        setUpdateCountAgenda={setUpdateCountAgenda}
        updateCountAgenda={updateCountAgenda}
        onClose={() => setOpenCommentaire(false)}
        profilName={title}
        selectedData={selectedData2}
        appointments={clientAppointment}
      />
    </Box>
  );
}
