import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  API_APPOINTMENTS,
  API_PLANNING,
  API_URL,
} from "../../utils/api/ApiPaths";
import { getClients } from "../../utils/api/Clients";

export interface ClientsType {
  id: number;
  name: string;
  vorname: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
}

const filter = createFilterOptions<any>();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateDialog = (props: any) => {
  const handleClose = () => {
    props.setModal(false);
  };

  const [clientNameList, setClientNameList] = useState<string[]>([]); // options for the autocomplete

  const [clienttmp, setClientTmp] = useState<string[]>([]);
  const [clientList, setClientList] = useState<Map<string, number>>(new Map()); // association name with id, so we can find easily the card after
  const [valueSend, setValueSend] = useState<any[]>([]);

  const getClientsList = async () => {
    getClients().then((response: any) => setClientTmp(response.data));
    let listName: string[] = [];
    let mapName = new Map();
    clienttmp.forEach((clients: any) => {
      if (clients.id) {
        listName.push(clients.name + " " + clients.vorname);
        mapName.set(clients.name, clients.id);
      }
    });
    setClientNameList(listName.sort());
    setClientList(mapName);
  };

  /** Send date to planning */
  const handleSend = async () => {
    let dateDefault = new Date(props.endDate);
    dateDefault.setMinutes(dateDefault.getMinutes() + 15);
    const newEvent = {
      startDate: props.startDate,
      endDate: dateDefault,
    };

    // Add the new event to the state
    props.setDataCalendar([props.dataCalendar, newEvent]);

    // Post the new event to the API

    valueSend.forEach(async (value) => {
      const response = await fetch(
        API_URL + API_PLANNING + "/" + value.id + API_APPOINTMENTS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",

          body: JSON.stringify(newEvent),
        }
      );
    });
    // Close the dialog
    props.setModal(false);
    setValueSend([]);
  };

  useEffect(() => {
    getClients().then((response: any) => setClientTmp(response.data));
    let listName: string[] = [];
    let mapName = new Map();
    clienttmp.forEach((clients: any) => {
      if (clients.id) {
        listName.push(clients.name + " " + clients.vorname);
        mapName.set(clients.name, clients.id);
      }
    });
    setClientNameList(listName.sort());
    setClientList(mapName);
  }, [props.modal]);

  return (
    <div>
      <Dialog
        open={props.modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Créer un rendez-vous"}</DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            id="destinationCard-select"
            options={clienttmp}
            onChange={(event, newValue) => {
              setValueSend(newValue);
            }}
            value={valueSend}
            groupBy={(option: any) => option.vorname[0].toLocaleUpperCase()}
            getOptionLabel={(option: any) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }

              // Regular option
              return option.vorname + " " + option.name;
            }}
            renderInput={(params: any) => (
              <TextField
                required
                {...params}
                label="Sélection des clients"
                placeholder="Nouveau client"
                sx={{ minWidth: "400px" }}
              />
            )}
            // ListboxProps={{ style: { maxHeight: "10rem" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginRight: "2%" }}
          >
            Annuler
          </Button>
          <Button variant="contained" onClick={handleSend}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateDialog;
