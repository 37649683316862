import { createFilterOptions } from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import * as React from "react";
import { API_CLIENTS, API_URL } from "../../utils/api/ApiPaths";

export interface ClientsType {
  id: number;
  name: string;
  vorname: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
}

const filter = createFilterOptions<any>();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDelete = (props: any) => {
  const handleClose = () => {
    props.setOpenDelete(false);
  };

  const handleSend = () => {
    console.log("???");
    console.log(props.selectedName);
    deleteClients(props.selectedId);
    props.setOpenDelete(false);
  };

  return (
    <div>
      <Dialog
        open={props.openDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Voulez-vous vraiment supprimer : ${props.selectedName} ?`}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginRight: "2%" }}
          >
            Annuler
          </Button>
          <Button variant="contained" onClick={handleSend}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export const deleteClients = (id: any) => {
  return axios
    .delete(API_URL + API_CLIENTS + "/" + id, { withCredentials: true })
    .catch((error) => {
      if (error) {
        console.log("Une erreur s'est produite");
      } else {
        // we don't know :'(
        console.error(error);
      }
    });
};
export default ModalDelete;
