import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import MenuSettings from "../customComponents/ParamPos";
import CheckFilter from "./Checkbox";
import MessageTo from "./MessageTo";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Relations = () => {
  const [disabledButton, setDisabledButton] = useState(true);
  const sendMessage = () => {
    console.log("sendMessage");
  };
  return (
    <Box
      sx={{
        marginTop: { xs: "25%", sm: "17%", md: "3%", lg: "2%" },
        marginLeft: { xs: "20%", sm: "17%", md: "3%", lg: "2%" },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0%",
          left: "43%",
          backgroundColor: "#9A00A7",
          paddingLeft: "3%",
          paddingRight: "3%",
        }}
      >
        <h3 style={{ color: "white" }}>Ma Communication</h3>
      </div>

      <div style={{ marginTop: "3%", marginLeft: "5%" }}>
        <div>
          <CheckFilter />
          <TextField
            id="outlined-search"
            label="Message à envoyer"
            type="text"
            sx={{
              width: { xs: "25ch", sm: "35ch", md: "56ch", lg: "56ch" },
              marginTop: "1%",
            }}
          />
        </div>
        <div style={{ marginTop: "3%" }}>
          <MessageTo />
        </div>

        <div style={{ marginTop: "1%" }}>
          <Button
            variant="outlined"
            onClick={sendMessage}
            disabled={disabledButton}
          >
            Envoyer
          </Button>
        </div>
      </div>
      <MenuSettings />
    </Box>
  );
};
export default Relations;
