// InvoiceTemplate.tsx
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 48,
    fontSize: 12,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  title2: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 5,
  },
  issuerInfo: {
    marginBottom: 20,
  },
  tableContainer: {
    marginTop: 80,
  },
  recipientInfo: {
    marginBottom: 20,
  },

  tableRow: {
    flexDirection: "row",
    marginBottom: 4,
  },
  tableCol: {
    width: "50%",
    backgroundColor: "#f2f2f2",

    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },

  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#6E9964",
    color: "#fff",
  },
  total: {
    textAlign: "right",
    marginRight: 20,
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 20,
  },
  paymentMethods: {
    marginTop: 80,
    pageBreak: "avoid", // Ajoutez cette ligne

  },
  logo: {
    position: "absolute",
    top: 24,
    right: 24,
    width: 100,
    height: 100,
  },
  date: {
    position: "absolute",
    top: 120,
    right: 24,
  },
});

interface InvoiceItem {
  name: string;
  price: number;
}

interface InvoiceTemplateProps {
  recipientName: string;
  recipientAddress: string;
  invoiceItems: InvoiceItem[];
  invoiceNumber: number;
  invoiceDate : string;
}

const InvoiceTemplate: React.FC<InvoiceTemplateProps> = ({
  recipientName,
  recipientAddress,
  invoiceItems,
  invoiceNumber,
  invoiceDate,
}) => {
  const total = invoiceItems.reduce(
    (accumulator, item: any) => accumulator + parseFloat(item.price || "0"),
    0
  );
  const tauxTVA = 0.2; // Le taux de TVA est de 20% selon le texte

  const totalHT = total / (1 + tauxTVA);
  const montantTVA = totalHT * tauxTVA; // Calcul du montant de la TVA

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Facture #{invoiceNumber}</Text>
        <View style={styles.issuerInfo}>
          <Text>Nine In One SARL - 889 921 995</Text>
          <Text>15 rue des Usines - ZA Les Abanis - LD La Française</Text>
          <Text>54730 Gorcy - France</Text>
          <Text>06.18.88.88.66 - t.bodyshock@outlook.com</Text>
          <Text>N° TVA : FR77889921995</Text>
        </View>
        <Image src={"./images/justeLogo.png"} style={styles.logo} />
        <Text style={styles.date}>{invoiceDate.toString()}</Text>
        <View style={styles.recipientInfo}>
          <Text style={styles.title2}>Destinataire :</Text>
          <Text>{recipientName}</Text>
          <Text>{recipientAddress}</Text>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableHeader]}>
              <Text>Libellé</Text>
            </View>
            <View style={[styles.tableCol, styles.tableHeader]}>
              <Text>Prix</Text>
            </View>
          </View>
          {invoiceItems.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.price.toFixed(2)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={styles.total}>Total HT : {totalHT.toFixed(2)} €</Text>

        <Text style={styles.total}> TVA : 20%</Text>
        <Text style={styles.total}>Montant de la tva : {montantTVA.toFixed(2)} €</Text> {/* Affichage du montant de la TVA */}

        <Text style={styles.total}>Total : {total.toFixed(2)} €</Text>

        <View style={styles.paymentMethods}>
          <Text style={styles.title}>Modes de paiement :</Text>
          <Text style={styles.title2}>Virement bancaire LCL</Text>
          <Text>IBAN : FR83 3000 2073 0000 0071 0693 T46 </Text>
          <Text>BIC : CRLYFRPP</Text>
          <Text style={styles.title2}>Chèque</Text>
          <Text>A l'ordre de Nine In One SARL</Text>

          <Text style={styles.title2}>Espèce</Text>
          <Text>Voir à l'accueil</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceTemplate;
