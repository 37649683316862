import { createTheme } from "@mui/material";
export const primaryColor = "#6E9964";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6E9964",
      contrastText: "#fff", //button text white instead of black
    },
  },

  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#6E9964",
        },
      },
    },
  },
});

export default theme;
