import {
  Check,
  Description,
  Edit,
  EventNote,
  ExpandMore,
  MonetizationOn,
  PictureAsPdf,
  Save
} from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  TextareaAutosize,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import axios from "axios";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  API_APPOINTMENTS,
  API_CLIENTS,
  API_URL
} from "../../utils/api/ApiPaths";
import InvoiceTemplate from "../facture/InvoiceTemplate";
import Editing from "./Editing";

interface PropsModal {
  isOpen: boolean;
  onClose: () => void;
  appointments: any;
  profilName?: string;
  selectedData?: any;
  setUpdateCountAgenda: any;
  updateCountAgenda : number;
}

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  const isPastDate = (date: string) => {
    const today = new Date();
    const appointmentDate = new Date(date);
    return appointmentDate < today;
  };
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export const ModalNotesAgenda: React.FC<PropsModal> = ({
  isOpen,
  onClose,
  appointments,
  profilName,
  selectedData,
  setUpdateCountAgenda,
  updateCountAgenda,

}: PropsModal) => {
  const [tempNotes, setTempNotes] = useState<string[]>([]);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  registerLocale("fr", fr);

  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date());
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };
  const textAreaWidth = isSmallScreen ? "100%" : "700px";
  const [isSaving, setIsSaving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState({
    price: "",
    // Ajoutez d'autres champs de facture si nécessaire
  });
  const [invoiceNumber, setInvoiceNumber] = useState(0);

  const [extraFields, setExtraFields] = useState<any[]>([]);
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleAddField = () => {
    setExtraFields([...extraFields, { label: "", price: "" }]);
  };
  useEffect(() => {
    {
      /** Remplacer valeur */
    }
    console.log(selectedData);
    setTempNotes(() =>
      appointments
        ? appointments.map((appointment: any) => appointment.notes || "")
        : []
    );
  }, [appointments]);

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
  };



  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  /** Facture number */
  const incrementInvoiceNumber = async () => {
    try {
      const response = await fetch(`${API_URL}/invoices/increment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}), // Ajoutez cette ligne

        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(
          `Failed to increment invoice number: ${response.status} - ${response.statusText}`
        );
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      throw new Error(`Failed to increment invoice number: ${error}`);
    }
  };

  useEffect(() => {
    const fetchInvoiceCount = async () => {
      try {
        const invoiceCount = await getInvoiceCount();
        setInvoiceNumber(invoiceCount);
      } catch (error) {
        console.error("Error getting invoice count:", error);
      }
    };

    if (isOpen || pdfGenerated) {
      fetchInvoiceCount();
      // Réinitialisez l'état pdfGenerated après la mise à jour du numéro de facture
      setPdfGenerated(false);
    }
  }, [isOpen, pdfGenerated]); // Ajoutez pdfGenerated aux dépendances

  const getInvoiceCount = async () => {
    try {
      const response = await fetch(`${API_URL}/invoices/count`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(
          `Failed to get invoice count: ${response.status} - ${response.statusText}`
        );
      }

      const responseData = await response.json();
      return responseData.invoiceCount; // Modifiez cette ligne
    } catch (error) {
      throw new Error(`Failed to get invoice count: ${error}`);
    }
  };

  const handleInvoiceDetailsChange = (event: any) => {
    const { name, value } = event.target;
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };
  const sortedAppointments = appointments?.sort(
    (a: any, b: any) =>
      new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
  );


  const handleSave = async (appointmentId: any, notes: string) => {
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false); // Faites disparaître l'icône Check après 3 secondes
    }, 3000);
    // Enregistrez les notes dans votre état global ou base de données
    try {
      const updatedAppointment = await updateAppointment(
        appointmentId,
        notes,
        selectedData
      );
      console.log("Notes sauvegardées :", updatedAppointment);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des notes :", error);
    }
  };

  const updateAppointment = async (
    appointmentId: string,
    updatedNotes: string,
    selectedData: any
  ) => {
    try {
      const response = await fetch(
        API_URL +
          API_CLIENTS +
          "/" +
          selectedData.idClient +
          API_APPOINTMENTS +
          "/" +
          appointmentId,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ notes: updatedNotes }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to update appointment: ${response.status} - ${response.statusText}`
        );
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      throw new Error(`Failed to update appointment: `);
    }
  };

  const handlePaymentValidation = async (
    appointmentId: string,
    selectedData: any
  ) => {
      const response = await fetch(
        API_URL +
          API_CLIENTS +
          "/" +
          selectedData.idClient +
          API_APPOINTMENTS +
          "/" +
          appointmentId,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ paid: false }),
        }
      );
      setUpdateCountAgenda(updateCountAgenda + 1)
        onClose()
        setSnackbarOpen(true);
      if (!response.ok) {
    
        throw new Error(
          `Failed to update appointment: ${response.status} - ${response.statusText}`
        );
      }

      const responseData = await response.json();
      setUpdateCountAgenda(updateCountAgenda)

      return responseData;
 

  };
  const handleDownloadPDF = async (appointment: any) => {
    setIsDownloading(true);
    console.log(selectedData);
  
    await incrementInvoiceNumber();
  
    // Ajoutez une requête getOne pour obtenir les informations d'un client
    const clientId = appointment.clientId; // Remplacez par l'ID de votre client
    const clientResponse = await axios.get(API_URL + API_CLIENTS+'/'+ selectedData.idClient); // Remplacez 'your-api-url' par l'URL de votre API
    const clientData = clientResponse.data;
  
    const recipientName = `${clientData.vorname} ${clientData.name}`; // Utilisez les données récupérées pour définir recipientName
    const recipientAddress = `${clientData.adress}, ${clientData.postal}, ${clientData.city}`; // Utilisez les données récupérées pour définir recipientAddress
  
    const invoiceItems = extraFields.map((field) => ({
      name: field.label || "",
      price: parseFloat(field.price || "0"),
    }));
  
    const pdfInstance = (
      <InvoiceTemplate
        recipientName={recipientName}
        recipientAddress={recipientAddress}
        invoiceItems={invoiceItems}
        invoiceNumber={invoiceNumber}
        invoiceDate={format(selectedDate, "dd/MM/yyyy")} // Formattez 'selectedDate' ici
      />
    );

    const blob = await pdf(pdfInstance).toBlob();

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "facture.pdf";
    link.click();
    URL.revokeObjectURL(url);
    setPdfGenerated(true);

    setIsDownloading(false);
  };

  const isPastDate = (date: string) => {
    const today = new Date();
    const appointmentDate = new Date(date);
    return appointmentDate < today;
  };
  
  const calculateTotal = () => {
    let total = parseFloat(invoiceDetails.price || "0");

    extraFields.forEach((field) => {
      total += parseFloat(field.price || "0");
    });

    return total.toFixed(2);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          {"Notes des rendez-vous de "}
          {selectedData?.vorname} ({sortedAppointments?.length})
        </DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab icon={<EventNote />} label="Rendez-vous" />
            <Tab icon={<Description />} label="Facturation" />
            <Tab icon={<Edit />} label="Editer" />

          </Tabs>
        </Box>
        <DialogContent
          sx={{
            maxHeight: sortedAppointments?.length ?? 0 > 2 ? "60vh" : "auto",
            overflowY: "auto",
          }}
        >
          <TabPanel value={activeTab} index={0}>
            {sortedAppointments && sortedAppointments.length > 0 ? (
              <Stack spacing={2}>
                {sortedAppointments.map((appointment: any, index: any) => (
                  <Box
                    key={appointment.startDate}
                    sx={{ border: 1, borderRadius: "md", p: 2 }}
                  >
                    <Typography fontWeight="bold"     sx={{
                        color: isPastDate(appointment.startDate) ? "green" : "",
                      }}>
                      {formatDate(appointment.startDate)}
                    </Typography>
                    <TextareaAutosize
                      style={{ width: textAreaWidth }}
                      value={tempNotes[index]}
                      onChange={(e) => {
                        const newTempNotes = [...tempNotes];
                        newTempNotes[index] = e.target.value;
                        setTempNotes(newTempNotes);
                      }}
                      placeholder="Saisissez vos notes ici..."
                      minRows={3}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      startIcon={isSaving ? <Check /> : <Save />}
                      sx={{ mt: 2 }}
                      onClick={() =>
                        handleSave(appointment.id, tempNotes[index])
                      }
                    >
                      {isSaving ? "Sauvegarde réussie" : "Enregistrer"}{" "}
                    </Button>
                  </Box>
                ))}
              </Stack>
            ) : (
              "aucune donnée"
            )}
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            {sortedAppointments && sortedAppointments.length > 0 ? (
              <Stack spacing={2}>
                {sortedAppointments.map((appointment: any, index: any) => (
                  <Accordion
                    key={appointment.startDate}
                    sx={{ backgroundColor: appointment.paid ? "#FFEBEB" : "" }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      sx={{
                        color: isPastDate(appointment.startDate) ? "green" : "",
                      }}
                    >
                      {formatDate(appointment.startDate)}

                      {" : "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {sortedAppointments.length - index}
                      </span>
                    </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={2}>
                        <Grid container spacing={2}></Grid>
                        {extraFields.map((field, index) => (
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={9}>
                              <TextField
                                label={`Libellé ${index + 1}`}
                                name={`extraFieldLabel${index}`}
                                value={field.label}
                                onChange={(e) => {
                                  const newExtraFields = [...extraFields];
                                  newExtraFields[index].label = e.target.value;
                                  setExtraFields(newExtraFields);
                                }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                label={`Prix ${index + 1}`}
                                name={`extraFieldPrice${index}`}
                                value={field.price}
                                onChange={(e) => {
                                  const newExtraFields = [...extraFields];
                                  newExtraFields[index].price = e.target.value;
                                  setExtraFields(newExtraFields);
                                }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        ))}
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              label="Total"
                              name="total"
                              value={calculateTotal()}
                              InputProps={{
                                readOnly: false,
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6} >
                          <DatePicker
    selected={selectedDate}
    onChange={handleDateChange}
    dateFormat="dd-MM-yyyy"
    customInput={<TextField label="Date de la facture" fullWidth />}
    locale="fr"

  />
                        </Grid>
                        </Grid>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={handleAddField}
                        >
                          Ajouter
                        </Button>
                      </Stack>
                    </AccordionDetails>
                    <AccordionActions>
                      {appointment.paid && (
                        <Button
                          color="primary"
                          variant="contained"
                          startIcon={<MonetizationOn />}
                          onClick={() =>
                            handlePaymentValidation(
                              appointment.id,
                              selectedData
                            )
                          }
                        >
                          Valider le paiement
                        </Button>
                      )}
                      <Button
                        color="secondary"
                        variant="contained"
                        startIcon={<PictureAsPdf />}
                        onClick={() => handleDownloadPDF(appointment)}
                      >
                        Générer la facture
                      </Button>
                    </AccordionActions>
                  </Accordion>
                ))}
              </Stack>
            ) : (
              false
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Editing        
              selectedId={selectedData?.idClient}
              selectedName={profilName}
              selectedData={selectedData}/>

        </TabPanel>
        <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}

             >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Paiement validé !
          </Alert>
        </Snackbar>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="error">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
