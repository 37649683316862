import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Slide,
  TextField,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@mui/styles";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { putClients } from "../../utils/api/Clients";

export type MyFormState = {
  actif: string;
  name: string;
  vorname: string;
  email: string;
  phone: string;
  adress: string;
  city: string;
  postal: string;
  payment: string;
  session: string;
  actualSession: number;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  dialog: {
    width: "800px",
    height: "600px",
  },
});

const ModalEdit = (props: any) => {
  const classes = useStyles();

  const [clients, setClients] = useState<MyFormState[]>([]);

  const handleClose = () => {
    props.setOpenEdit(false);
  };

  const [state, setState] = useState<MyFormState>({
    actif: "",
    name: "",
    vorname: "",
    email: "",
    phone: "",
    adress: "",
    city: "",
    postal: "",
    payment: "",
    session: "",
    actualSession: 0,
  });

  useEffect(() => {
    // Fetch data from API
    setState({
      actif: props.selectedData.actif,
      name: props.selectedData.name,
      vorname: props.selectedData.vorname,
      email: props.selectedData.email,
      phone: props.selectedData.phone,
      adress: props.selectedData.adress,
      city: props.selectedData.city,
      postal: props.selectedData.postal,
      payment: props.selectedData.payment,
      session: props.selectedData.session,
      actualSession: props.selectedData.actualSession,
    });
  }, [props.openEdit]);

  const formRef = useRef<HTMLFormElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    setState({ ...state, [name]: value });
  };

  /** Submit */
  const handleSubmit = (event: FormEvent) => {
    console.log(props.selectedData);

    console.log(event);
    event.preventDefault();
    const form = formRef.current;
    if (!form) return;

    const data = new FormData(form);
    const client = Object.fromEntries(data);
    // Envoyer le client ici en utilisant une API ou autre méthode

    putClients(props.selectedId, client);
    resetForm();
    props.setOpenEdit(false);
  };

  const resetForm = () => {
    setState({
      actif: "actif",
      name: "",
      vorname: "",
      email: "",
      phone: "",
      adress: "",
      city: "",
      postal: "",
      payment: "",
      session: "",
      actualSession: 0,
    });
  };

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={props.openEdit}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Modification de : ${props.selectedName} `}
        </DialogTitle>
        <div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <FormControl style={{ width: "92%", marginLeft: "2%" }}>
              <InputLabel id="demo-simple-select-actif" htmlFor="actif">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-actif"
                native
                label="Status"
                inputProps={{
                  name: "actif",
                  id: "actif",
                }}
                value={state.actif}
                onChange={handleChangeSelect}
              >
                <option value="actif">Actif</option>
                <option value="inactif">Inactif</option>
                <option value="prospect">Prospect</option>
              </Select>
            </FormControl>
            <br />
            <TextField
              label="Prénom"
              name="vorname"
              id="vorname"
              fullWidth
              value={state.vorname}
              onChange={handleChange}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
            />
            <TextField
              label="Nom"
              name="name"
              id="name"
              fullWidth
              value={state.name}
              onChange={handleChange}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
            />

            <br />
            <TextField
              label="Email"
              type="email"
              name="email"
              id="email"
              fullWidth
              value={state.email}
              onChange={handleChange}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
            />
            <TextField
              label="Téléphone : +33"
              name="phone"
              id="phone"
              fullWidth
              value={state.phone}
              onChange={handleChange}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
            />
            <br />
            <TextField
              label="Code Postal"
              name="postal"
              id="postal"
              fullWidth
              value={state.postal}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              onChange={handleChange}
            />
            <TextField
              label="Ville"
              name="city"
              id="city"
              fullWidth
              value={state.city}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              onChange={handleChange}
            />

            <TextField
              label="Complément d'adresse"
              name="adress"
              id="adress"
              fullWidth
              value={state.adress}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
              onChange={handleChange}
            />
            <FormControl
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
            >
              <InputLabel id="demo-simple-select-label" htmlFor="payment">
                Méthode de paiement
              </InputLabel>
              <Select
                value={state.payment}
                labelId="demo-simple-select-label"
                native
                label="Méthode de paiement"
                inputProps={{
                  name: "payment",
                  id: "payment",
                }}
              >
                <option value="mounth">Paiement par mois</option>
                <option value="one">Paiement en une fois</option>
              </Select>
            </FormControl>
            <br />
            <TextField
              label="Nombre de séance"
              name="session"
              id="session"
              fullWidth
              value={state.session}
              onChange={handleChange}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
            />

            <TextField
              label="Séance réalisée :"
              name="actualSession"
              id="actualSession"
              fullWidth
              value={state.actualSession}
              onChange={handleChange}
              sx={{ width: "45%", marginLeft: "2%", marginTop: "3%" }}
            />
          </form>
        </div>

        <DialogActions sx={{ marginTop: "2%" }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginRight: "2%" }}
          >
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={handleSubmit}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalEdit;
