import { ChangeEvent, FormEvent, useState } from "react";
// Nav
import { useNavigate } from "react-router-dom";
// i18n

// MUI IMPORTS
import { Send, Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ApiService from "../../utils/api/Service";
import AuthService from "../../utils/authentication/Service";

// INTERFACE
interface LoginProps {
  directTo: string;
  previousEmail?: string;
}

/**
 * SignInForm component. It collects the login info from the user.
 * It handles the whole form, submit & API calls & error management included.
 * The api call is done using the AuthService.
 * The error management is done in this component as the errors must be interpreted.
 * @param props contains the "directTo" which will be the navigated target after the signin succeeds
 */
const SignInForm = ({ directTo, previousEmail }: LoginProps) => {
  //  nav
  let navigate = useNavigate();
  // i18n
  const { t } = useTranslation(["forms", "translation"]);
  // STATE
  const [email, setEmail] = useState(previousEmail ?? "");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [formHelperText, setFormHelperText] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // reset error & helpers
  const reset = () => {
    setEmailError(false);
    setEmailHelperText("");
    setPasswordError(false);
    setPasswordHelperText("");
    setFormHelperText("");
  };

  // INPUT CHANGE
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    reset();
    switch (event.target.name) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        console.error("InputChange > Unknown Field");
        break;
    }
  };

  // validation helper
  const validateForm = () => {
    // reset
    reset();
    return true;
  };

  // SUBMIT
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitInProgress(true);

    // form validation
    if (!validateForm()) {
      setSubmitInProgress(false);
      return;
    }

    // API CALL
    AuthService.login(email, password)
      .then(() => {
        navigate(directTo, { replace: true });
      })
      .catch((error: any) => {
        // catching errors from the login call
        if (error === ApiService.ERROR_INVALID_CREDENTIALS) {
          // invalid credentials
          setFormHelperText(t("error.form.credentials"));
          setEmailError(true);
          setPasswordError(true);
        } else {
          // we don't know :'(
          console.error(error);
          setEmailError(true);
          setPasswordError(true);
          setFormHelperText(t("error.form.back"));
        }
        setSubmitInProgress(false);
      });
  };

  // RETURN
  return (
    <Box
      sx={{
        marginTop: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h3">{t("signInForm.title")}</Typography>
      <hr style={{ width: "30%" }} />
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "800px",
        }}
      >
        <TextField
          margin="normal"
          required
          sx={{ width: "400px" }}
          id="email"
          label={t("label.email")}
          name="email"
          autoComplete="text"
          autoFocus
          error={emailError}
          helperText={emailHelperText}
          onChange={handleInputChange}
          value={email}
        />
        <TextField
          margin="normal"
          required
          sx={{ width: "400px" }}
          name="password"
          label={t("label.password")}
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
          id="password"
          autoComplete="current-password"
          error={passwordError}
          helperText={passwordHelperText}
          onChange={handleInputChange}
          value={password}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* Form Helper */}
        <Typography sx={{ color: "error.main" }}>{formHelperText}</Typography>
        {/* Bouton d'envoi */}
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          endIcon={<Send />}
          loading={submitInProgress}
          loadingPosition="end"
        >
          {t("signInForm.button.submit")}
        </LoadingButton>
        {/* Liens vers les autres options 
        <Grid container>
          <Grid item xs>
            <Link href="/reset-password" variant="body2">
              {t("translation:forgetPassword")}
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signup" variant="body2">
              {t("translation:createAccount")}
            </Link>
          </Grid>
        </Grid>
				  */}
      </Box>
    </Box>
  );
};

export default SignInForm;
