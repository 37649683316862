import { SyntheticEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// UI
//MUI
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";

/**
 * This Snackbar is used to show information to user through different URLs.
 * It gather its parameters from the location.sate.data.
 * The "snack" object should contain:
 * - message: message to show in the snackbar
 * - severity (AlertColor): "success" | "info" | "warning" | "error"
 * @returns Snackbar with the desired message and severity
 */
const SnackPage = () => {
  // STATE
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info" as AlertColor);

  const location = useLocation();
  const data: any = location.state;

  useEffect(() => {
    if (data && data.snack) {
      setMessage(data.snack.message);
      setSeverity(data.snack.severity);
      setOpen(true);
    } else setOpen(false);
  }, [data]);

  // Snack behaviour
  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Alert severity={severity} sx={{ width: "100%" }} action={action}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackPage;
