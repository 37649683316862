import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

export default function MessageTo() {
  return (
    <Stack
      spacing={3}
      sx={{ width: { xs: "25ch", sm: "35ch", md: "56ch", lg: "56ch" } }}
    >
      <Autocomplete
        multiple
        id="tags-outlined"
        options={clienttmp}
        getOptionLabel={(option) => option.title}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sélection des clients"
            placeholder="Envoyer à"
          />
        )}
      />
    </Stack>
  );
}

const clienttmp = [
  { title: "Laura Moreau", year: 1994 },
  { title: "Valentin Hajek", year: 1972 },
  { title: "Gael Husson", year: 1974 },
  { title: "Maxime", year: 2008 },
  { title: "Rémi", year: 1957 },
  { title: "Julien Pinto", year: 1993 },
  { title: "Antoine", year: 1994 },
  { title: "Valentin Azerty", year: 1972 },
  { title: "Julien Alzod", year: 1993 },
  { title: "Antoine Dalz", year: 1994 },
];
