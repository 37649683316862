import { Button, Dialog, DialogActions, Slide } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { putClients } from "../../utils/api/Clients";

export type MyFormState = {
  actif: string;
  name: string;
  vorname: string;
  email: string;
  phone: string;
  adress: string;
  city: string;
  postal: string;
  payment: string;
  session: string;
  paid: boolean;
  actualSession: number;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalPaiement = (props: any) => {
  const [clients, setClients] = useState<MyFormState[]>([]);

  const handleClose = () => {
    props.setOpenPaiement(false);
  };

  const [state, setState] = useState<MyFormState>({
    actif: "",
    name: "",
    vorname: "",
    email: "",
    phone: "",
    adress: "",
    city: "",
    postal: "",
    payment: "",
    session: "",
    paid: true,
    actualSession: 0,
  });

  useEffect(() => {
    // Fetch data from API
  }, [props.openPaiement]);

  const formRef = useRef<HTMLFormElement>(null);

  /** Submit */
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const objectData = {
      paid: false,
    };
    console.log(objectData);

    await putClients(props.selectedId, objectData).then(() => {
      props.setOpenPaiement(false);
    });
  };

  return (
    <div>
      <Dialog
        open={props.openPaiement}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Valider le paiement de  : ${props.selectedName} `}
        </DialogTitle>

        <DialogActions sx={{ marginTop: "2%" }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginRight: "2%" }}
          >
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={handleSubmit}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalPaiement;
