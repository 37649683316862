import { CloseOutlined, Delete, Edit, ViewAgenda } from "@mui/icons-material";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getClients } from "../../utils/api/Clients";
import MenuSettings from "../customComponents/ParamPos";
import CreateCompleteDialog from "./CreateCompleteDialog";
import { CustomerTableSearch } from "./CustomerTableSearch";
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
import { ModalNotes } from "./ModalNotes";
import ModalPaiement from "./ModalPaiement";
interface RowData {
  email: string;
  name: string;
}

const TableClients = (props: any) => {
  const columns = [
    {
      field: "fullName",
      headerName: "Nom",
      width: 150,
      resizable: true,
      valueGetter: (params: any) =>
        `${params.row.vorname || ""} ${params.row.name || ""}`,
    },
    {
      field: "session",
      headerName: "Séances",
      minWidth: 150,
      renderCell: (params: any) => (
        <p>
          {params.row.actualSession} / {params.row.session}
        </p>
      ),
    },
    {
      field: "email",
      headerName: "Mail",
      minWidth: 200,
      renderCell: (params: any) => (
        <a
          href={`mailto:${params.row.email}`}
          style={{ color: "#6e9964", width: "100%" }}
        >
          {params.row.email}
        </a>
      ),
    },
    {
      field: "phone",
      headerName: "Téléphone",
      minWidth: 150,
      renderCell: (params: any) => (
        <a href={`tel:${params.row.phone}`} style={{ color: "#6e9964" }}>
          {params.row.phone}
        </a>
      ),
    },
    /*
    {
      field: "payment",
      headerName: "Paiement",
      minWidth: 150,
      autoSize: true,
      renderCell: (params: any) => (
        <Button
          variant="outlined"
          onClick={() => {
            if (params.row.paid === true) {
              handleOpenPaiement(
                params.id,
                params.row.name,
                params.row.vorname,
                params.row
              );
            }
            if (params.row.paid === false) {
              setMessageSnackbar(
                `${params.row.vorname} ${params.row.name} est à seulement ${params.row.actualSession} séances, il ne doit pas encore effectuer de paiement`
              );
              setOpenSnackbar(true);
            }
          }}
        >
          <MonetizationOn sx={{ color: params.row.paid ? "red" : "green" }} />
        </Button>
      ),
    },
    */
    {
      field: "x",
      headerName: "Edition",
      minWidth: 150,

      renderCell: (params: any) => (
        <Button
          variant="outlined"
          onClick={() =>
            handleOpenEdit(
              params.id,
              params.row.name,
              params.row.vorname,
              params.row
            )
          }
        >
          <Edit />
        </Button>
      ),
    },
    {
      field: "xxxx",
      headerName: "Séances",
      minWidth: 150,

      renderCell: (params: any) => (
        <Button
          variant="outlined"
          onClick={() =>
            handleOpenCommentaire(
              params.id,
              params.row.name,
              params.row.vorname,
              params.row
            )
          }
        >
          <ViewAgenda />
        </Button>
      ),
    },
    {
      field: "xx",
      headerName: "Supprimer",
      minWidth: 150,

      renderCell: (params: any) => (
        <Button
          variant="outlined"
          onClick={() =>
            handleOpenDelete(params.id, params.row.name, params.row.vorname)
          }
        >
          <Delete sx={{ color: "red" }} />
        </Button>
      ),
    },
  ];

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedData2, setSelectedData2] = useState<any | null>(null);
  const [filter, setFilter] = useState("");

  const [tableData, setTableData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const filteredCustomers = displayedData.filter((customer:any) =>
    `${customer.vorname} ${customer.name} ${customer.phone} ${customer.email}`
      .toLowerCase()
      .includes(filter.toLowerCase())
  );
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState("");

  const [openPaiement, setOpenPaiement] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openCommentaire, setOpenCommentaire] = useState(false);

  const [messageSnackbar, setMessageSnackbar] = useState("");

  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };
  const handleOpenDelete = (id: any, name: any, vorname: any) => {
    setSelectedName(name + " " + vorname);
    setSelectedId(id);
    setOpenDelete(true);
  };

  const handleOpenEdit = (id: any, name: any, vorname: any, data: any) => {
    setSelectedName(name + " " + vorname);
    setSelectedData(data);
    setSelectedId(id);
    setOpenEdit(true);
  };

  const handleOpenPaiement = (id: any, name: any, vorname: any, data: any) => {
    setSelectedName(name + " " + vorname);
    setSelectedData(data);
    setSelectedId(id);
    setOpenPaiement(true);
  };
  const handleOpenCommentaire = (
    id: any,
    name: any,
    vorname: any,
    data: any
  ) => {
    console.log("==>", data);
    setSelectedName(name + " " + vorname);
    setSelectedData2(data);
    setSelectedId(id);
    setOpenCommentaire(true);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseOutlined fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  useEffect(() => {
    getClients().then((response: any) => {
      setTableData(response.data);
      setIsLoading(false);
      let filteredData = response.data;
      if (props.queryStatus !== "all") {
        filteredData = tableData.filter(
          (client: any) => client.actif === props.queryStatus
        );
      }
      setDisplayedData(filteredData);
    });
  }, [
    openDelete,
    props.modal,
    openEdit,
    props.queryStatus,
    isLoading,
    openPaiement,
    openCommentaire,
  ]);
  const [filterButtonEl, setFilterButtonEl] =
    React.useState<HTMLButtonElement | null>(null);
  return (
    <div>
        <CustomerTableSearch onFilterChange={handleFilterChange} />

    <div style={{ height: 700, width: "100%", overflow: "auto", marginTop: "0.5%" }}>

      {isLoading ? (
        <div>Chargement en cours...</div>
      ) : (
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          rows={filteredCustomers}
          columns={columns}
          pageSize={100}
        />
      )}

      <ModalDelete
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        selectedId={selectedId}
        selectedName={selectedName}
      />
      <ModalEdit
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        selectedId={selectedId}
        selectedName={selectedName}
        selectedData={selectedData}
      />
      <ModalPaiement
        openPaiement={openPaiement}
        setOpenPaiement={setOpenPaiement}
        selectedId={selectedId}
        selectedName={selectedName}
        selectedData={selectedData}
      />
      <ModalNotes
        isOpen={openCommentaire}
        onClose={() => setOpenCommentaire(false)}
        selectedData={selectedData2}
        appointments={selectedData2?.appointments}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        action={action}
      >
        <Alert severity="error">{messageSnackbar} !</Alert>
      </Snackbar>
      <CreateCompleteDialog modal={props.modal} setModal={props.setModal} />
      <MenuSettings />
    </div>
    </div>
  );
};

export default TableClients;
