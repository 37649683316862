import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";

interface Props {
  onFilterChange: (filter: string) => void;
}

export const CustomerTableSearch: React.FC<Props> = ({ onFilterChange }) => {
  const [filter, setFilter] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilter(value);
    onFilterChange(value);
  };

  return (
    <TextField
      placeholder="Rechercher..."
      value={filter}
      onChange={handleFilterChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};
